import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const PropertyHeaderDetail = ({ property }) => {
  return (
    <div className="cha-pd-container__header">
      <div className="upper-gradient"></div>
      <div className="cha-pd-container__header__inner">
        <span className="info">
          {property.UnparsedAddress}
        </span>
        <h5 className="h5-header">
          ${new Intl.NumberFormat().format(property.ListPrice)}
        </h5>
        <div className="cha-pd-container__header__inner__third-text">
          <StaticImage src="../images/bed-icon.png" alt="beds" />
          <span class="info">{property.BedroomsTotal || 0}</span>
          <StaticImage src="../images/shower-icon.png" alt="beds" />
          <span class="info">{property.BathroomsTotalInteger || 0}</span>
          <StaticImage src="../images/rule-icon.png" alt="beds" />
          <span class="info" >{new Intl.NumberFormat().format(property.LivingArea) || 0}</span>
          {property.VirtualTourURLUnbranded ? (
            <Link to={property.VirtualTourURLUnbranded}>
              <StaticImage src="../images/play-icon.png" alt="beds" />
              <span class="info">VIRTUAL TOUR</span>
            </Link>
          ) : null}
        </div>
      </div>
      <div className="lower-gradient"></div>
    </div>
  )
}

export default PropertyHeaderDetail
