import React from "react"
import ImageGallery from "react-image-gallery"

const Gallery = ({ galleryImage })=> {
  return (
    <div className={`cha-pd-container__gallery cha-pd-container__gallery__exclusive gallery-container containers`}>
      <h1 className="cha-pd-container__gallery__title h1-header">Gallery</h1>
        <ImageGallery
        items={galleryImage}
        autoPlay={false}
        showPlayButton={false}
        showFullscreenButton={false}
        />
    </div>
  )
}

export default Gallery;
